<template>
  <div class="reservation">
    <div class="header">
      <Label customClass="page-title" color="primary" size="medium" uppercase>
        {{ $t(`pages.preApprovalPage.title`) }}
      </Label>
    </div>
    <div class="content">
      <div class="header--container">
        <div class="car-img--container">
          <img
            class="car-img"
            :src="img"
            :alt="'vehicle-image-' + getReservationVehicle.model"
          />
        </div>
        <PriceModelDetails
          class="mb-6"
          icon-name="info-circle"
          noTooltip
          :vehicle="getReservationVehicle"
          :absolutePrice="!getReservationWithFinance"
          :product-title="getReservationVehicle.model"
          :product-description="getReservationVehicle.commercialDescription"
          :tooltip-description="getReservationVehicle.tooltipDescription"
          :price-label="getVehicleTotalPriceOrPvpFormatted"
          :modality="formattedMonthlyPrice"
          :finance-taeg="formattedTaeg"
        />
      </div>
      <div class="separator"></div>
      <div>
        <Label color="primary" size="small" bold="bold" :uppercase="true">
          {{ $t(`pages.preApprovalPage.subtitles.1`) }}
        </Label>
      </div>
      <div>
        <Label color="tertiary" size="x-small"
          >{{ $t(`pages.preApprovalPage.descriptions.1-1`) }}
          <b>{{ $t(`pages.preApprovalPage.descriptions.1-2`) }}</b
          >.</Label
        >
      </div>
      <div class="labelBox vertical">
        <div class="personal">
          <DisplayLabelValue :data="getReservationUserWithLabel" />
          <Select
            class="input-form mt-2"
            id="marital-status-select"
            :options="statuses"
            :border="false"
            @onChange="setMaritalStatus"
            :disabled="getPreApprovalIsSubmited"
          />
          <Label
            v-if="errs.maritalStatus"
            color="tertiary"
            bold="thin"
            size="small"
            class="validate-message"
          >
            {{ errs.maritalStatus }}</Label
          >
        </div>
        <div class="receipt">
          <DisplayLabelValue :data="getReservationReceiptWithLabels" />
          <CountryPicker class="input-form" @onCountry="setNationality" />
        </div>
      </div>
      <div class="identification">
        <div>
          <Label
            customClass="with-spacing"
            color="tertiary"
            size="small"
            bold="bold"
            >Documento de Identificação</Label
          >
        </div>
        <div class="identification-type--radio">
          <div class="identification-type--radio-option1">
            <RadioButtonLabel
              title="Cartão de Cidadão"
              radioId="c"
              radioSize="small"
              radioColor="secondary"
              radioName="radio"
              radioCircular
              radioValue="cc"
              titleColor="primary"
              titleFor="cc"
              :disabled="getPreApprovalIsSubmited"
              subtitleColor="tertiary"
              :radioChecked="CCChecked"
              @onChange="onChange"
            />
          </div>
          <div class="identification-type--radio-option2">
            <RadioButtonLabel
              title="Bilhete de Identidade Civil"
              radioId="bi"
              radioSize="small"
              radioColor="secondary"
              radioName="radio"
              radioCircular
              radioValue="bi"
              titleColor="primary"
              titleFor="bi"
              :disabled="getPreApprovalIsSubmited"
              :radioChecked="BIChecked"
              subtitleColor="tertiary"
              @onChange="onChange"
            />
          </div>
        </div>
        <div class="labelBox vertical">
          <div class="identification-inputs" v-if="ccIdentification">
            <div>
              <CpInput
                ref="identificationCC"
                :validator="validateCC"
                customClass="id-form"
                :label="identificationLabel"
                @onChange="setIdValue"
                :disabled="getPreApprovalIsSubmited"
                :value="getIdValue"
              />
            </div>
            <div>
              <CpInput
                ref="ccDigit"
                :validator="validateCheckDigit"
                customClass="id-digit"
                label="Check Digito"
                @onChange="setIdControlValue"
                :disabled="getPreApprovalIsSubmited"
                :value="getIdControlValue"
              />
            </div>
          </div>
          <div class="identification-inputs" v-if="!ccIdentification">
            <div>
              <CpInput
                ref="identificationBI"
                :validator="validateBI"
                customClass="id-form"
                :label="identificationLabel"
                @onChange="setIdValue"
                :disabled="getPreApprovalIsSubmited"
                :value="getIdValue"
              />
            </div>
            <div>
              <CpInput
                ref="ccDigit"
                :validator="validateCheckDigitBI"
                customClass="id-digit"
                label="Check Digito"
                @onChange="setIdControlValue"
                :disabled="getPreApprovalIsSubmited"
                :value="getIdControlValue"
              />
            </div>
          </div>
          <div>
            <CpDatePicker
              ref="idValidationDate"
              label="Data de Validade"
              :value="getIdExpireDate"
              class="id-date-form"
              :disabled="getPreApprovalIsSubmited"
              @onChange="handleValidationDate"
            />
          </div>
        </div>
        <div class="separator"></div>
      </div>
      <div>
        <Label color="primary" size="small" bold="bold" :uppercase="true">
          {{ $t(`pages.preApprovalPage.subtitles.2`) }}
        </Label>
      </div>
      <div>
        <Label color="tertiary" size="x-small"
          >{{ $t(`pages.preApprovalPage.descriptions.2`) }}.</Label
        >
      </div>
      <div class="labelBox vertical">
        <div class="general">
          <Label
            customClass="with-spacing"
            color="tertiary"
            size="small"
            bold="bold"
            >{{ $t(`pages.preApprovalPage.subtitles.2-1`) }}</Label
          >
          <div class="form">
            <CpInput
              ref="occupation"
              :validator="validateEmpty"
              customClass="input-form"
              label="Profissão *"
              @onChange="setOccupation"
              :disabled="getPreApprovalIsSubmited"
              :value="getOccupation"
            />
            <CpInput
              ref="company"
              :validator="validateEmpty"
              customClass="input-form"
              label="Empresa *"
              @onChange="setCompany"
              :disabled="getPreApprovalIsSubmited"
              :value="getCompany"
            />
            <CpInput
              ref="occupationTime"
              :validator="validateNumber"
              customClass="input-form"
              label="Antiguidade Emprego (em meses) *"
              @onChange="setOccupationTime"
              :disabled="getPreApprovalIsSubmited"
              :value="getOccupationTime"
            />
            <CpInput
              ref="monthlyEarnings"
              customClass="input-form"
              :validator="validateNumber"
              label="Vencimento mensal liquido *"
              @onChange="setMonthlyEarnings"
              :disabled="getPreApprovalIsSubmited"
              :value="getMonthlyEarnings"
            />
            <Select
              class="input-form"
              id="contract-type-select"
              :options="contractTypes"
              :disabled="getPreApprovalIsSubmited"
              :border="false"
              @onChange="setContractType"
            />
            <Label
              v-if="errs.contractType"
              color="tertiary"
              bold="thin"
              size="small"
              class="validate-message"
            >
              {{ errs.contractType }}</Label
            >
          </div>
        </div>
        <div class="finantial vertical">
          <Label
            customClass="with-spacing"
            color="tertiary"
            size="small"
            bold="bold"
            >{{ $t(`pages.preApprovalPage.subtitles.2-2`) }}</Label
          >
          <div class="form">
            <Select
              class="input-form"
              id="housing-type-select"
              :options="housingType"
              :disabled="getPreApprovalIsSubmited"
              :border="false"
              @onChange="setHousingType"
            />
            <Label
              v-if="errs.housingType"
              color="tertiary"
              bold="thin"
              size="small"
              class="validate-message"
            >
              {{ errs.housingType }}</Label
            >
            <CpInput
              ref="housingTime"
              :validator="validateNumber"
              customClass="input-form"
              label="Antiguidade Habitação (em anos) *"
              @onChange="setHousingTime"
              :disabled="getPreApprovalIsSubmited"
              :value="getHousingTime"
            />
          </div>
        </div>
      </div>
      <div class="separator"></div>
      <div>
        <Label color="primary" size="small" bold="bold" :uppercase="true">
          {{ $t(`pages.preApprovalPage.subtitles.3`) }}
        </Label>
      </div>
      <div class="labelBox vertical">
        <div class="bank-info">
          <CpInput
            ref="iban"
            :validator="validateIban"
            customClass="input-form"
            label="IBAN *"
            @onChange="setIban"
            :disabled="getPreApprovalIsSubmited"
            :value="getIban"
          />
        </div>
        <div class="info">
          <CpInput
            ref="accountTime"
            :validator="validateNumber"
            customClass="input-form"
            label="Antiguidade da Conta (em anos) *"
            @onChange="setAccountTime"
            :disabled="getPreApprovalIsSubmited"
            :value="getAccountTime"
          />
          <Label
            v-if="errs.accountTime"
            color="tertiary"
            bold="thin"
            size="small"
            class="validate-message"
          >
            {{ errs.accountTime }}</Label
          >
        </div>
      </div>
      <div class="separator"></div>
      <div class="pay-term">
        <div>
          <Label color="primary" size="small" bold="bold">
            {{ $t(`pages.preApprovalPage.subtitles.4`) }}
          </Label>
        </div>
        <div>
          <Label color="tertiary" size="x-small">
            {{ $t(`pages.preApprovalPage.descriptions.3`) }}
          </Label>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="close-btn">
        <Button
          :disabled="getPreApprovalIsSubmited"
          @onClick="submit"
          color="primary"
          label="Submeter"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
import { Label, Button, Select } from "../../../atoms";
import CpInput from "../../../refactor/CpInput.vue";
import CpDatePicker from "../../../refactor/CpDatePicker.vue";
import {
  DisplayLabelValue,
  PriceModelDetails,
  CountryPicker,
  RadioButtonLabel
} from "../../../molecules";
import IBAN from "iban";
// import { MARITAL_STATUS } from "../../../../enums/maritalStatus";

import { CREDIT_ENTITY } from "../../../../enums/creditEntity";
import v from "../../../../utils/validator";
import LABEL_TYPE from "../../../../utils/labelType";
import formValidator from "../../../../utils/formValidator";
import { PAGES } from "../../../../enums/pages";

export default {
  name: "ReservationDetails",
  components: {
    Label,
    DisplayLabelValue,
    PriceModelDetails,
    Button,
    Select,
    CountryPicker,
    CpInput,
    RadioButtonLabel,
    CpDatePicker
  },
  data() {
    return {
      isSubmited: false,
      ccIdentification: true,
      identificationLabel: "Número do Cartão de Cidadão",
      dropdowns: {},
      errs: {},
      statuses: [
        { text: this.$t("select.maritalStatus.0"), value: -1, disabled: true }
      ],
      contractTypes: [
        { text: this.$t("select.contractType.0"), value: -1, disabled: true }
      ],
      housingType: [
        { text: this.$t("select.housingType.0"), value: -1, disabled: true }
      ],
      formData: {
        creditEntity: 1,
        reservationId: "",
        userInfo: {
          identification: {
            type: 1,
            value: "",
            controlValue: "",
            expDate: ""
          },
          nationality: "",
          maritalStatus: "",
          profissionalActivity: {
            occupation: "",
            company: "",
            occupationTime: "",
            contractType: "",
            monthlyEarnings: ""
          },
          housingActivity: {
            housingType: "",
            housingTime: ""
          },
          bankInfo: {
            accountTime: "",
            nib: ""
          }
        }
      }
    };
  },
  methods: {
    ...mapMutations([
      "setOccupation",
      "setIban",
      "setHousingTime",
      "setHousingType",
      "setAccountTime",
      "setMonthlyEarnings",
      "setContractType",
      "setCompany",
      "setOccupationTime",
      "setMaritalStatus",
      "setNationality",
      "setIban",
      "setIdType",
      "setIdValue",
      "setIdControlValue",
      "setIdExpDate"
    ]),
    handleValidationDate(value) {
      this.$store.commit("setIdExpDate", value);
    },
    onChange(event) {
      if (event === "bi") {
        this.ccIdentification = false;
        this.identificationLabel = "Bilhete de identidade";
      } else {
        this.ccIdentification = true;
        this.identificationLabel = "Número do Cartão de Cidadão";
      }
    },
    validateIban(e) {
      if (!IBAN.isValid(e)) {
        return [LABEL_TYPE.ERROR, "IBAN Inválido"];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateNumber(e) {
      if (!e) {
        return [LABEL_TYPE.ERROR, "Campo obrigatório"];
      }
      if (!Number(e)) {
        return [LABEL_TYPE.ERROR, "Só são aceites valores numéricos"];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateCC(e) {
      const reg = /^[0-9]{8}$/;
      if (this.ccIdentification) {
        if (!e) {
          return [LABEL_TYPE.ERROR, "Campo obrigatório"];
        }
        if (!Number(e)) {
          return [LABEL_TYPE.ERROR, "Só são aceites valores numéricos"];
        }
        if (!reg.test(e)) {
          return [LABEL_TYPE.ERROR, "Formato errado - 8 dígitos"];
        }
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateBI(e) {
      const reg = /^[0-9]{8}$/;
      if (!this.ccIdentification) {
        if (!e) {
          return [LABEL_TYPE.ERROR, "Campo obrigatório"];
        }
        if (!Number(e)) {
          return [LABEL_TYPE.ERROR, "Só são aceites valores numéricos"];
        }
        if (!reg.test(e)) {
          return [LABEL_TYPE.ERROR, "Formato errado - 8 dígitos"];
        }
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateCheckDigit(e) {
      const reg = /^[a-zA-Z\d]{4}$/;
      if (this.ccIdentification) {
        if (!e) {
          return [LABEL_TYPE.ERROR, "Campo obrigatório"];
        }
        if (!reg.test(e)) {
          return [LABEL_TYPE.ERROR, "Formato errado - 4 caracteres"];
        }
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateCheckDigitBI(e) {
      const reg = /^[a-zA-Z\d]{1}$/;
      if (!this.ccIdentification) {
        if (!e) {
          return [LABEL_TYPE.ERROR, "Campo obrigatório"];
        }
        if (!reg.test(e)) {
          return [LABEL_TYPE.ERROR, "Formato errado - 1 caracter"];
        }
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    validateEmpty(e) {
      if (!e) {
        return [LABEL_TYPE.ERROR, "Campo obrigatório"];
      }
      return [LABEL_TYPE.SUCCESS, ""];
    },
    async submit() {
      this.errs = v("preApproval", this.getFlattenData);
      console.log("Errors", this.errs, this.getFlattenData);

      const refs = [
        // {
        //   ref: "identificationCC",
        //   value: this.getIdValue
        // },
        // {
        //   ref: "ccDigit",
        //   value: this.getIdControlValue
        // },
        // {
        //   ref: "identificationBI",
        //   value: this.getIdValue
        // },
        // {
        //   ref: "idValidationDate",
        //   value: this.getIdExpireDate
        // },
        {
          ref: "occupation",
          value: this.getOccupation
        },
        {
          ref: "company",
          value: this.getCompany
        },
        {
          ref: "occupationTime",
          value: this.getOccupationTime
        },
        {
          ref: "monthlyEarnings",
          value: this.getMonthlyEarnings
        },
        {
          ref: "housingTime",
          value: this.getHousingTime
        },
        {
          ref: "iban",
          value: this.getIban
        },
        {
          ref: "accountTime",
          value: this.getAccountTime
        }
      ];

      const errs = formValidator(refs, this.$refs);

      if (Object.values(this.errs).some(Boolean) || errs.length > 0) {
        this.$toast.open({
          message: "Existem campos em falta",
          dismissible: true,
          type: "error"
        });
        return;
      }

      this.$store.commit("spinnerModule/setLoading", true, { root: true });
      this.$store.commit("setReservationId", this.$route.params.id);
      this.$store.commit("setCreditEntity", CREDIT_ENTITY.CETELEM);

      if (this.ccIdentification) {
        this.$store.commit("setIdType", 1);
      } else {
        this.$store.commit("setIdType", 2);
      }

      await this.$store
        .dispatch("requestPreApprovalPayload")
        .then(result => {
          if (result.status === 200) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eec.financiamento",
              model: this.getReservationVehicle.model
            });
            this.$toast.open({
              message: this.$t("toast.preApproval.preApprovalSubmitted"),
              dismissible: true,
              type: "info"
            });
            setTimeout(
              () => this.$router.push({ name: PAGES.RESERVATIONS }),
              500
            );
          } else {
            this.$toast.open({
              message: this.$t("toast.preApproval.preApprovalSubmittedError"),
              dismissible: true,
              type: "error"
            });
            setTimeout(
              () => this.$router.push({ name: PAGES.RESERVATIONS }),
              500
            );
          }
        })
        .catch(error => {
          console.error(error);
          this.$toast.open({
            message: this.$t("toast.preApproval.preApprovalSubmittedError"),
            dismissible: true,
            type: "error"
          });
          setTimeout(
            () => this.$router.push({ name: PAGES.RESERVATIONS }),
            500
          );
        });
      this.$store.commit("spinnerModule/setLoading", false, { root: true });
    }
  },
  computed: {
    ...mapGetters([
      "getFlattenData",
      "getCurrentReservation",
      "getReservationUser",
      "getReservationReceipt",
      "getReservationVehicle",
      "getReservationUserWithLabel",
      "getReservationReceiptWithLabels",
      "getReservationWithFinance",
      "getVehicleTotalPriceOrPvpFormatted",
      "getReservationPreApproval",
      "getPreApprovalDropdowns",
      "getPreApprovalIsSubmited",
      "getPreApprovalPayload",
      "getNationality",
      "getMaritalStatus",
      "getOccupation",
      "getOccupationTime",
      "getCompany",
      "getContractType",
      "getMonthlyEarnings",
      "getHousingType",
      "getHousingTime",
      "getAccountTime",
      "getIban",
      "getIdType",
      "getIdValue",
      "getIdControlValue",
      "getIdExpireDate"
    ]),
    CCChecked() {
      return this.getIdType === 1;
    },
    BIChecked() {
      return this.getIdType === 2;
    },
    img() {
      return (
        this.getReservationVehicle?.imageUrl ||
        require("../../../../assets/images/placeholder.jpg")
      );
    },
    formattedTaeg() {
      return (
        "TAEG: " + Number(this.getReservationVehicle?.taeg).toFixed(2) + " %"
      );
    },
    formattedMonthlyPrice() {
      return Number(this.getReservationVehicle?.monthlyPrice) + " €";
    }
  },
  async mounted() {
    this.formData = this.getReservationPreApproval || this.formData;

    await this.$store.dispatch("requestPreApprovalDropdowns");

    this.statuses = [
      ...this.statuses,
      ...Object.keys(this.getPreApprovalDropdowns.maritalStati).map(key => ({
        text: this.$t(
          `preApprovalDropdowns.maritalStati.${this.getPreApprovalDropdowns.maritalStati[key]}`
        ),
        value: this.getPreApprovalDropdowns.maritalStati[key],
        selected: this.getPreApprovalPayload
          ? this.getPreApprovalDropdowns.maritalStati[key] ===
            this.getPreApprovalPayload.userInfo.maritalStatus
          : false
      }))
    ];
    this.contractTypes = [
      ...this.contractTypes,
      ...Object.keys(this.getPreApprovalDropdowns.contractTypes).map(key => ({
        text: this.$t(
          `preApprovalDropdowns.contractTypes.${this.getPreApprovalDropdowns.contractTypes[key]}`
        ),
        value: this.getPreApprovalDropdowns.contractTypes[key],
        selected: this.getPreApprovalPayload
          ? this.getPreApprovalDropdowns.contractTypes[key] ===
            this.getPreApprovalPayload.userInfo.profissionalActivity
              .contractType
          : false
      }))
    ];
    this.housingType = [
      ...this.housingType,
      ...Object.keys(this.getPreApprovalDropdowns.housingTypes).map(key => ({
        text: this.$t(
          `preApprovalDropdowns.housingTypes.${this.getPreApprovalDropdowns.housingTypes[key]}`
        ),
        value: this.getPreApprovalDropdowns.housingTypes[key],
        selected: this.getPreApprovalPayload
          ? this.getPreApprovalDropdowns.housingTypes[key] ===
            this.getPreApprovalPayload.userInfo.housingActivity.housingType
          : false
      }))
    ];
  }
};
</script>

<style scoped>
.identification {
  @apply mt-8;
}
.identification-type--radio {
  @apply md:flex;
  align-items: center;
}

.identification-type--radio-option1 {
  @apply mb-3;
  @apply md:mb-0;
}

.identification-type--radio-option1 .label {
  font-size: 21px;
  vertical-align: middle;
}

.identification-type--radio-option2 .label {
  font-size: 21px;
  vertical-align: middle;
}

.identification-type--radio-option2 {
  @apply md:ml-6;
}

input[type="radio"] {
  vertical-align: middle;
}
:deep(.id-form) {
  background-color: white;
  @apply w-full;
}
:deep(.bi-form) {
  background-color: white;
  @apply w-full;
  @apply sm:w-3/4;
}

:deep(.id-digit) {
  background-color: white;
  @apply w-full;
  @apply mt-4;
  @apply ml-0;
}
.id-date-form :deep(.container) {
  background-color: white;
  @apply w-full;
  @apply sm:w-3/4;
}

.identification-inputs {
  @apply grid;
  @apply grid-cols-1;
}
@media (min-width: 1395px) {
  .identification-inputs {
    @apply grid;
    grid-template-columns: 7fr 3fr;
  }
  :deep(.id-digit) {
    @apply mt-0;
    @apply ml-4;
  }
}
.header {
  border-bottom: 2px solid #e4ecf0;
  @apply pb-5;
}

.content {
  @apply mt-5;
  background-color: #f5f7f9;
  @apply p-10;
}

.page-title {
  font-size: 24px;
}

.separator {
  @apply pt-6;
  @apply mb-6;
  border-bottom: 2px solid #e4ecf0;
}

.footer {
  @apply mt-10;
  @apply flex;
  @apply justify-end;
}

.footer .button {
  width: 182px;
  height: 50px;
}

.close-btn {
  @apply ml-6;
}

.labelBox {
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: 1fr 1fr;
  margin-top: 20px;
}

@media screen and (max-width: 770px) {
  .labelBox {
    grid-template-columns: 1fr;
    gap: 20px;
  }
}

.header--container {
  display: flex;
  align-items: center;
}

.car-img {
  object-fit: contain;
  object-position: center;
  width: 170px;
  height: 110px;
}

.car-img--container {
  display: flex;
  margin-right: 1rem;
}

.pay-term {
  background-color: #e4ecf0;
  @apply p-6;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.validate-message {
  font-size: 12px;
  @apply font-head;
  @apply font-normal;
  line-height: 14px;
  color: #e63312;
}

:deep(.input-form) {
  background-color: white;
  @apply w-full;
  @apply sm:w-3/4;
  /* @apply sm:mr-4;
  @apply mb-4;
  @apply sm:mb-0; */
}

:deep(.selectCustom),
:deep(.selectWrapper),
:deep(.selectNative),
:deep(.selectCustom-option) {
  width: 100%;
}

:deep(.custom--input) {
  background-color: white;
}

:deep(.finance-model-price) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-modality) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

:deep(.finance-model-taeg) {
  font-size: 20px !important;
  line-height: 20px !important;
  font-weight: 700 !important;
}

@media (max-width: 1440px) {
  :deep(.finance-model-price.finance--container-title) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-modality.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }

  :deep(.finance-model-taeg.finance--container-subtitle) {
    font-size: 20px !important;
    line-height: 20px !important;
    font-weight: 700 !important;
  }
}

:deep(.finance--wrapper) {
  margin-bottom: 0px;
}

@media (max-width: 768px) {
  :deep(.header--container) {
    flex-direction: column;
  }

  :deep(.car-img--container) {
    margin: 0 0 20px 0;
    width: 100%;
  }

  :deep(.car-img--container) img {
    margin: 0px;
    width: 100%;
  }

  .content {
    padding: 16px;
  }

  .footer {
    flex-direction: column;
    padding: 16px;
    margin-top: 0;
  }

  .footer .close-btn {
    width: 100%;
    margin: 0;
  }

  .footer .close-btn .button {
    width: 100%;
    margin: 4px 0;
  }
}
</style>
